import { BigNumber } from "ethers";

const NETWORK_PROVIDER = process.env.REACT_APP_NETWORK_PROVIDER || process.env.NETWORK_PROVIDER || 'other'
export const shouldUseRinkeby = NETWORK_PROVIDER === 'rinkeby'

export const provider =
  process.env.NODE_ENV === 'production' && !shouldUseRinkeby
    ? {
      name: 'Polygon',
      API_URL: 'https://polygon-rpc.com/',
      CHAIN_ID: 137,
      API_KEY: ''
    }
    : {
      name: 'Rinkeby - ETH Testnet',
      API_URL: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      CHAIN_ID: 4,
      API_KEY: '9aa3d95b3bc440fa88ea12eaa4456161'
    };

export const MAX_APPROVAL_VALUE = BigNumber.from("2").pow("256").sub("1");
import { shouldUseRinkeby } from "./provider-data";

const addresses =
    process.env.NODE_ENV === 'production' && !shouldUseRinkeby
        ? {
            AUCTION_ADDRESS: '0x', // Proxy
            PIXU_REWARD_ADDRESS: '0x7ac303A8FB1Fd119c19D4f182ED2f48a7392f9C6',
            PIXU_CAT_TOKEN_ADDRESS: '0x',
            CLAIM_CONTRACT_ADDRESS: '0x', // Proxy
            SKULLOID_CONTRACT: '0xfd4F6AfA4da383b6ff4aF8F5d6554B8e70c94760',
            SKULLOID_MINTER_CONTRACT: '0x21CEB42F0Db6dADB0855D6Aeb3670Da055A2d8be',
            MOCK_WETH_TOKEN_CONTRACT: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', // Token to Pay for the NFTs
            SKULLOID_CLAIMER_CONTRACT: '0x477FcC9fD578897de0240cE9753B3C832CC3a845',
            VIP_PIXU_TOKEN_ADDRESS: '0xe1656029a6b78eeae46F76bA2b6c689016196Df9',
        }
        : {
            AUCTION_ADDRESS: '0x', // Proxy testnet
            PIXU_REWARD_ADDRESS: '0x2eAa2A6F2f5Eb5951DE72FEc06527109173915d7',
            PIXU_CAT_TOKEN_ADDRESS: '0x',
            CLAIM_CONTRACT_ADDRESS: '0x', // Proxy testnet
            SKULLOID_CONTRACT: '0x9F8a68ad81A90eBf0B8B65b4560E301c3322393b',
            SKULLOID_MINTER_CONTRACT: '0x269620971A860f6fe8D549aac2b9f7c1B426faA9',
            MOCK_WETH_TOKEN_CONTRACT: '0x2393ce2ae6293cfddc38678d879e97eb9b52a172',
            SKULLOID_CLAIMER_CONTRACT: '0x7B3D2b55FCe6DFb313d8eB18d259eA2a6d298001',
            VIP_PIXU_TOKEN_ADDRESS: '0xdB0932C99e387d1a5934E2F538C7508E15ce59A9'
        };

export default addresses;
